.page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/contain */
  contain: size layout style;
}
