@supports (-webkit-appearance: -apple-pay-button) {
  .root {
    width: 100%;
    height: 48px;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    cursor: pointer;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .root {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
    cursor: pointer;
  }
  .black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .white-outline {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}
