.buttonContainer {
  height: 48px !important;
}
.androidNativeButtonContainer {
}
.androidNativeButtonContainer > button {
  border-radius: 24px;
  -webkit-box-shadow: 0px 0px 1px 1px #757775 !important;
  box-shadow: 0px 0px 1px 1px #757775 !important;
}
